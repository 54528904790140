.footer {
    text-align: center;
    padding: 10px;
    color: #777777;
}

@media (max-width: 600px) {
    .footer {
        font-size: 14px;
        
    }
}