.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
    /* Ensures a minimum height of 75% of the viewport height */
    background-color: #d9f9d6;
    /* Light green background color */
    position: relative;
    width: 100%;
    /* Ensures full width */
    color: white;
    padding: 20px;
    /* Add padding to prevent content from touching edges */
    box-sizing: border-box;
}

.banner-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    /* Full height of the banner */
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
}

.text-content {
    flex: 1;
    padding-right: 20px;
    color: black;
    /* Ensure text is visible */
    min-width: 300px;
    /* Ensure a minimum width */
}

.image-content {
    flex: 1;
    height: 100%;
    /* Full height of the container */
    margin-left: 20px;
    /* Shift image a bit to the right */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    /* Ensure a minimum width */
}

.image-content img {
    height: 100%;
    /* Image takes full height of the container */
    width: 100%;
    /* Image width is constrained by container width */
    object-fit: cover;
    /* Ensures the image covers the area without distortion */
    border-radius: 10px;
}

.text-content p {
    font-size: 18px;
    color: black;
    margin-top: 10px;
    line-height: 1.5;
}

@media (max-width: 900px) {
    .banner-content {
        flex-direction: column;
        text-align: center;
    }

    .text-content {
        padding-right: 0;
        padding-bottom: 20px;
    }

    .image-content {
        width: 100%;
        /* Full width on smaller screens */
        margin-left: 0;
        height: auto;
        /* Adjust height for smaller screens */
    }

    .image-content img {
        width: 100%;
        height: auto;
        /* Adjust height for smaller screens */
    }

    .text-content h1 {
        font-size: 24px;
        /* Smaller font size for smaller screens */
    }

    .text-content p {
        font-size: 16px;
        /* Smaller font size for smaller screens */
    }
}