.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

@media (max-width: 600px) {
    .whatsapp-icon {
        bottom: 10px;
        right: 10px;
    }
}