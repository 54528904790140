.image-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Four columns, each taking 1 fraction of the available space */
    gap: 20px;
    /* Space between the images */
    margin: 20px 0;
}

.image-gallery img {
    width: 100%;
    /* Full width of the grid cell */
    height: 500px;
    /* Fixed height */
    object-fit: cover;
    /* Ensures the images cover the area without distortion */
    border-radius: 10px;
}

@media (max-width: 1200px) {
    .image-gallery {
        grid-template-columns: repeat(3, 1fr);
        /* Three columns on medium screens */
    }
}

@media (max-width: 900px) {
    .image-gallery {
        grid-template-columns: repeat(2, 1fr);
        /* Two columns on small screens */
    }
}

@media (max-width: 600px) {
    .image-gallery {
        grid-template-columns: 1fr;
        /* Single column on extra small screens */
    }

    .image-gallery img {
        height: 300px;
        /* Adjust height for smaller screens */
    }
}