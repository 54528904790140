.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #d9f9d6;
    flex-wrap: wrap;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    flex: 1;
    margin-right: 15px;
}

.phone {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    flex: 1;
    text-align: right;
}

.phone-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.phone-icon {
    margin-right: 10px;
}

@media (max-width: 600px) {
    .header {
        flex-direction: column;
        text-align: center;
    }

    .phone {
        text-align: center;
        margin-top: 10px;
    }

    .phone-link {
        justify-content: center;
    }
}